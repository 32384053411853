import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import Title from "../components/Title";
import Section from "../components/Section";
import styles from "./shop.module.css";

export default function Shop(props) {
  const { allShopJson } = props.data;
  return (
    <>
      <Helmet>
        <title>Shop</title>
        <meta property="og:title" content="Lunsford Ridge Farm | Shop" />
        <meta
          property="og:url"
          content="https://www.lunsfordridgefarm.com/shop"
        />
      </Helmet>
      <Section extraClasses={[styles.wrapper]}>
        {allShopJson.edges.map((e) => (
          <Link
            key={e.node.slug}
            to={`/shop/${e.node.slug}`}
            className={styles.categorywrapper}
          >
            <Title nowrap>{e.node.title}</Title>
            <div className={styles.imagewrapper}>
              <Img
                className={styles.image}
                style={{ position: "absolute" }}
                fluid={e.node.blurb.image.childImageSharp.fluid}
                fadeIn={false}
                alt={e.node.title}
              />
            </div>
          </Link>
        ))}
      </Section>
    </>
  );
}

export const pageQuery = graphql`
  query {
    allShopJson(sort: { fields: priority, order: DESC }) {
      edges {
        node {
          slug
          title
          icon {
            publicURL
            childImageSharp {
              fluid(traceSVG: { color: "black" }, quality: 100, maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          ...BlurbFragmentShop
        }
      }
    }
  }
`;
